import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const products = [
  {
    title: "Solvent based products",
    description: 'In addition to the above setup STAY ON produce Solvent/Acrylic based products like BOPP Tapes, Polyester Tapes, Cotton Tapes, Aluminium Foil Tapes, release coated papers etc., on our 40" & 60" width coaters with online printing facilities. STAY ON also manufacture different range of Industrial Self Adhesive Tapes which have a wide application in Paper, Packing and Printing Industry etc.,'
  },
  {
    title: "Experience our company",
    description: "With our proven record and experience our company offers you a Self Adhesive Product for every need and every market. For over 20 years, STAY ON has been reacting daily to customer needs, creating value - added solutions with pressure -. sensitive products that deliver advantage in performance, cost and availability."
  },
  {
    title: "Our priority",
    description: "Our number one priority is the customer. There are several reasons why STAY ON has such an enviable reputation for quality - The most important of all is a commitment to excellence at every stage from customer and employee relations to actual productions and delivery, tolerating no compromise. STAY ON 's approach allows to gain intimate knowledge of your challenges, capabilities and concerns and enables us to work with you to build long and lasting relationships."
  },
];

export default function ProductRange() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="w-full px-6 md:px-12">
        <div className="w-full px-6 md:px-12 flex gap-6">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                >
                  <div className="p-4 rounded-md mb-4 text-[#0d2251]">
                    <h3 className="text-xl font-semibold mb-2 text-center">
                      {product.title}
                    </h3>
                    <p>{product.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
