import { Link } from 'react-router-dom';
import { Handshake, Heart, Users, CheckSquare, ShoppingCart, Search } from 'lucide-react';

const products = [
  {
    icon: Handshake, // Represents reliability and trust
    image: '/home_images/home-image-1.png',
    title: 'Reliable',
    description: 'We build trust through dependable services, ensuring our customers can count on us at every stage, delivering on promises with consistency and integrity.',
    iconColor: '#0069d9', // Blue for reliability
    titleColor: '#0069d9', // Blue for reliability
  },
  {
    icon: Heart, // Represents dedication and commitment
    image: '/home_images/home-image-2.png',
    title: 'Dedicated',
    description: 'Our team commits wholeheartedly to each project, putting in extra effort to achieve excellence, focusing on customer satisfaction, and driving success through unwavering dedication.',
    iconColor: '#e53e3e', // Red for passion and commitment
    titleColor: '#e53e3e', // Red for passion and commitment
  },
  {
    icon: Users, // Represents teamwork and cooperation
    image: '/home_images/home-image-3.png',
    title: 'Cooperative',
    description: 'Collaboration is at the heart of our culture. We believe in working closely with clients and partners to create solutions that meet shared goals and foster mutual success.',
    iconColor: '#48bb78', // Green for collaboration and growth
    titleColor: '#48bb78', // Green for collaboration and growth
  },
  {
    icon: CheckSquare, // Represents quality and standards
    image: '/home_images/home-image-4.png',
    title: 'Quality System',
    description: 'We adhere to stringent quality control measures, continuously refining our processes to guarantee top-tier products and services, ensuring consistency and customer satisfaction.',
    iconColor: '#2b6cb0', // Dark blue for professionalism and standards
    titleColor: '#2b6cb0', // Dark blue for professionalism and standards
  },
  {
    icon: ShoppingCart, // Represents purchasing and procurement
    image: '/home_images/home-image-5.png',
    title: 'Purchasing',
    description: 'Our purchasing strategy focuses on acquiring high-quality materials cost-effectively, emphasizing sustainable and reliable sourcing to support our commitment to excellence.',
    iconColor: '#38b2ac', // Teal for reliability and transaction
    titleColor: '#38b2ac', // Teal for reliability and transaction
  },
  {
    icon: Search, // Represents inspection and thorough checking
    image: '/home_images/home-image-6.png',
    title: 'Inspection',
    description: 'Through thorough inspection practices, we ensure that each product meets rigorous standards, maintaining quality and reliability from start to finish in every project.',
    iconColor: '#dd6b20', // Orange for thoroughness and attention
    titleColor: '#dd6b20', // Orange for thoroughness and attention
  },
];

export default function AboutUsValues() {
  return (
    <section className="bg-gray-50">
      <div className="w-full">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-[#0d2251] mb-4">Our Values</h2>
        </div>

        <div className="py-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <div className="flex items-center justify-center bg-[#e0eaf4] p-4 rounded-md mb-4">
                <product.icon
                  className="w-8 h-8"
                  style={{ color: product.iconColor }} // Dynamically set the icon color
                />
                <h3
                  className="text-xl font-semibold ml-4 text-center"
                  style={{ color: product.titleColor }} // Dynamically set the title color
                >
                  {product.title}
                </h3>
              </div>
              <p>{product.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
