import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const products = [
  {
    image: '/product_images/tape1.png', // Add the correct image path
    title: 'Transfer Adhesive Tapes',
    code: 'Code: SP-CS-103'
  },
  {
    image: '/product_images/tape2.png', // Add the correct image path
    title: 'Tissue Tapes',
    code: 'Code: CS-104'
  },
  {
    image: '/product_images/tape3.png', // Add the correct image path
    title: 'Non-Woven Tapes',
    code: 'Code: CS-105'
  },
  {
    image: '/product_images/tape4.png', // Add the correct image path
    title: 'Double Side Paper Tapes',
    code: 'Code: CS-201'
  },
  {
    image: '/product_images/tape56.png', // Add the correct image path
    title: 'Double Side Film Tapes',
    code: 'Code: CS-202'
  },
  {
    image: '/product_images/tape56.png', // Add the correct image path
    title: 'Double Side Cloth Tapes',
    code: 'Code: CS-203'
  },
  {
    image: '/product_images/tape7.png', // Add the correct image path
    title: 'Aluminium Foil Tapes',
    code: 'Code: CS-301'
  },
  {
    image: '/product_images/tape8.png', // Add the correct image path
    title: 'B.O.P.P. Tapes',
    code: 'Code: CS-401'
  },
  {
    image: '/product_images/tape9.png', // Add the correct image path
    title: 'Foam Tapes',
    code: 'Code: CS-405'
  },
  {
    image: '/product_images/tape10.png', // Add the correct image path
    title: 'Glossy Tapes',
    code: 'Code: CS-102'
  },
];

export default function Tapes() {
  return (
    <section className="py-20 bg-gray-300">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h1 className="text-6xl font-bold text-[#0d2251] mb-4">Tapes</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <div className="h-[200px] w-full flex items-center justify-center overflow-hidden rounded-md mb-4">
                <img
                  src={product.image}
                  alt={product.title}
                  className="object-contain h-full w-full"
                />
              </div>
              <div className="bg-[#e0eaf4] p-4 rounded-md mb-4">
                <h3 className="text-xl font-semibold text-[#0d2251] mb-2 text-center">
                  {product.title}
                </h3>
                <h6 className="text-xl font-semibold text-[#ff0e18] mb-2 text-center">
                  {product.code}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
