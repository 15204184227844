import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function HeroQualtiy() {
  return (
    <img 
      src="/quality_culture_images/products-banner-image.jpg" 
      alt="Business Solutions" 
      className="w-full h-[600px] object-cover rounded-md" // Full width, custom height, and cover for aspect ratio
    />
  );
}
