import { useState } from 'react';
import {
  Mail,
  MapPin,
  MessageSquare,
  Phone,
  Send,
} from 'lucide-react';
import HeroContact from '../components/contact/Hero.js';
import CompanyDetails from '../components/contact/CompanyDetails.js';
import ContactUsForm from '../components/contact/ContactForm.js';
import FactoryDetails from '../components/contact/FactoryDetails.js';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log('Form submitted:', formData);
  };

  const handleChange = (
    e
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      <HeroContact />
      <CompanyDetails />
      <FactoryDetails />
      <ContactUsForm />
    </div>
  );
}