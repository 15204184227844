import { Phone, Mail, MapPin, Printer } from 'lucide-react';

export default function FactoryDetails() {
  return (
    <div className="bg-white py-8 px-4 md:px-12">
      <h2 className="text-4xl font-bold text-[#0d2251] mb-6 text-center">Factory:</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Phone Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <Phone size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Phone</h2>
          <p className="text-gray-700">+91-40-2379 2220, 2379 2221</p>
        </div>

        {/* Fax Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <Printer size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Fax</h2>
          <p className="text-gray-700">+91-40-2379 2320</p>
        </div>

        {/* Address Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <MapPin size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Address</h2>
          <p className="text-gray-700">STAY ON PAPERS Pvt LTD Plot No : 31 & 32, Apparel Export Park, Gundlapochampally, Medchal Mandal R.R.District, Secunderabad -500 014 Telangana, INDIA.</p>
        </div>
      </div>
    </div>
  );
}
