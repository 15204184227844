import Hero from '../components/home/Hero.js';
import IndustriesServed from '../components/home/IndustriesServed.js';
import OurSkills from '../components/home/OurSkills.js';
import WhoWeAre from '../components/home/WhoWeAre.js';
import Products from '../components/home/Products.js';

export default function Home() {
  return (
    <div>
      <Hero />
      <Products />
      <WhoWeAre />
      <OurSkills />
      <IndustriesServed />
    </div>
  );
}