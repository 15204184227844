import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

export default function AboutDetails() {
  return (
    <div className="flex flex-col lg:flex-row w-full h-auto lg:h-[600px] rounded-md overflow-hidden items-center">
      
      {/* Text Section - 100% width on mobile, 60% on desktop */}
      <div className="w-full lg:w-3/5 p-4 lg:p-8 flex flex-col justify-center space-y-4 lg:space-y-6">
        {/* Heading */}
        <h1 className="text-2xl lg:text-3xl font-bold text-[#0d2251]">The First Name in Self Adhesive Products</h1>
        
        {/* Subheading */}
        <h2 className="text-base lg:text-xl text-[#0d2251]">
          STAY ON PAPERS is one of the pioneers in adhesive coating, setup with American Knowhow and equipment. Our coating process is based on the latest Hot Melt Adhesive Technology with 10″, 20″ and new 40″ Coater to master the fast growing market needs.
          <br /><br />
          The Slot die spray coating system adopted ensures final products of the highest possible quality and consistency. Our Label Stock is very convenient to use on any machine like Flat Bed and Rotary Letter Press, Flexography, Offset, Silk Screen etc.
          <br /><br />
          STAY ON‘s Self Adhesive Label Stock is specially produced for use on Automatic Printing Machines. State of the art processing, accurate coating, winding etc., together with the use of superior grades of material and very demanding Quality Control, ensuring with in and release liners Production, absolute precision, consistent quality and proven reliability. All the Label Stock we produce is made to customers specifications in every aspect. Type of Paper, Adhesive Strength, Width, Length in reels or cut sheet – the choice is yours. Try our Products in high speed Label Printing and die cutting for efficient operation and long shelf life.
        </h2>
      </div>

      {/* Image Section - 100% width on mobile, 40% on desktop */}
      <img 
        src="/about_us_images/aboutus_details.jpg" 
        alt="Business Solutions" 
        className="w-full lg:w-2/5 h-64 lg:h-full object-cover rounded"
      />
    </div>
  );
}
