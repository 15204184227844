import { Code2, Menu, X } from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const menuItems = [
  { path: '/', label: 'Home' },
  { path: '/about', label: 'About Us' },
  { path: '/quality', label: 'Quality Culture' },
  { path: '/products', label: 'Products' },
];

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className="fixed w-full bg-white shadow-md z-50 h-[80px] md:h-[100px]"> {/* Adjust height as needed */}
      <div className="flex items-center justify-between h-full px-4">
        {/* Logo shifted to the right with left margin */}
        <Link to="/" className="flex items-center space-x-2 ml-12 h-full">
          <img src="/home_images/logo-stay-on-paper.png" alt="Mediviz" className="h-full w-auto max-h-[60%]" /> {/* Limit height for logo */}
        </Link>

        {/* Desktop Menu and Mobile Menu Button aligned to the right */}
        <div className="flex items-center pr-12 h-full">
          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-4 mr-12 h-full items-center">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`text-lg font-medium transition-colors hover:text-[#ff0e18] ${
                  location.pathname === item.path ? 'text-[#ff0e18]' : 'text-[#2e2f77]'
                } py-2 px-4 rounded h-full flex items-center`}
              >
                {item.label}
              </Link>
            ))}
            {/* Get A Quote Button */}
            <Link to="/contact">
              <button
                className="text-lg font-medium transition-colors bg-[#2e2f77] text-white hover:bg-[#ff0e18] py-2 px-4 rounded-full"
                onClick={() => setIsMenuOpen(false)}
              >
                Get A Quote
              </button>
            </Link>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden h-full flex items-center"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-[#2e2f77]" />
            ) : (
              <Menu className="h-6 w-6 text-[#2e2f77]" />
            )}
          </button>
        </div>
      </div>

      {/* Overlay for Mobile Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setIsMenuOpen(false)}></div>
      )}

      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="fixed top-0 right-0 w-3/4 h-full bg-white z-50 p-6 shadow-lg md:hidden">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`block text-lg font-medium transition-colors hover:text-[#cf772f] ${
                location.pathname === item.path ? 'text-[#ff0e18]' : 'text-[#2e2f77]'
              } py-3 px-4 rounded`}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
          {/* Get A Quote Button for Mobile Menu */}
          <Link to="/contact">
            <button
              className="text-lg font-medium transition-colors bg-[#2e2f77] text-white hover:bg-[#cf772f] py-2 px-4 rounded-full mt-4"
              onClick={() => setIsMenuOpen(false)}
            >
              Get A Quote
            </button>
          </Link>
        </nav>
      )}
    </header>
  );
}
