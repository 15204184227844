import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function History() {
  return (
    <div className="flex flex-col md:flex-row w-full h-auto md:h-[500px] rounded-md overflow-hidden">
      {/* Image Section - Full width on mobile, 40% width on desktop */}
      <img 
        src="/home_images/home-image-4.png" 
        alt="Business Solutions" 
        className="w-full md:w-2/5 h-64 md:h-full object-cover"
      />
      
      {/* Text Section - Full width on mobile, 60% width on desktop */}
      <div className="w-full md:w-3/5 bg-gray-300 p-4 md:p-8 flex flex-col justify-center space-y-4 md:space-y-6">
        {/* Heading */}
        <h1 className="text-2xl md:text-3xl font-bold text-[#0d2251]">Our commitment</h1>
        
        {/* Subheading */}
        <h2 className="text-lg md:text-xl text-[#0d2251]">Committed to delivering best quality products!</h2>
        
        {/* Card Section */}
        <div className="bg-white shadow-md p-4 md:p-6 rounded-md">
          <p className="text-[#0d2251] mb-4">
            This commitment to our customers has allowed STAY ON to grow by constantly reacting to the need for new applications, with different varieties of standard and customized products manufactured every year – helping in your pressure-sensitive needs.
          </p>
        </div>
      </div>
    </div>
  );
}
