import AboutDetails from "../components/about/AboutDetails.js";
import AboutUsValues from "../components/about/AboutUsValues.js";
import HeroAbout from "../components/about/Hero.js";
import History from "../components/about/History.js";
import ProductRange from "../components/about/ProductRange.js";

export default function About() {

  return (
    <div>
      <HeroAbout />   
      <AboutDetails />
      <History />
      <ProductRange />
      <AboutUsValues />
    </div>
  );
}
