import ProductsHero from '../components/products/Hero.js';
import LabelStockProducts from '../components/products/LabelStockProducts.js';
import Tapes from '../components/products/Tapes.js';

export default function Products() {
  return (
    <div>
      <ProductsHero />
      <LabelStockProducts />
      <Tapes />
    </div>
  );
}