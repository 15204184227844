import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const products = [
  {
    image: "/home_images/management_review.jpg",
    title: "Management Review",
    description: "The quality system adopted to satisfy the requirements of this Indian standard shall be reviewed at appropriate intervals by us to ensure its continuing suitability and effectiveness. Records of such reviews shall be maintained."
  },
  {
    image: "/home_images/quality.jpg",
    title: "Quality System",
    description: "We shall establish and maintain a documented quality system as means of ensuring that product conforms to specified requirements."
  },
  {
    image: "/home_images/document_control.jpg",
    title: "Document Control",
    description: "We shall establish and maintain procedures to control all documents and data that relate to the requirements of this Indian standard."
  },
  {
    image: "/home_images/inspection_testing.jpg",
    title: "Inspection Testing",
    description: "We shall ensure that incoming products is not used or processed until it has been inspected or otherwise verified as confirming to specified requirements."
  },
];

export default function OurSkills() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12 text-[#0d2251]">
          <h2 className="text-3xl font-bold mb-4">Our Skills</h2>
          <p>
            Stay On Papers specializes in the production of
            pressure-sensitive adhesive-based label stock and release liner
            solutions. The company is dedicated to delivering high-performance
            products that meet the needs of various industries. Here are some
            similar phrases
          </p>
        </div>

        <div className="w-full flex flex-col lg:flex-row gap-6">
          {/* Products Section - 100% width on mobile, 60% on large screens */}
          <div className="w-full lg:w-3/5">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {products.map((product, index) => (
                <div
                  key={index}
                  className="bg-white p-4 sm:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                >
                  <img
                    src={product.image}
                    alt={product.title}
                    className="h-20 w-full object-contain rounded-md mb-4"
                  />
                  <div className="bg-[#e0eaf4] p-4 rounded-md mb-4">
                    <h3 className="text-lg sm:text-xl font-semibold text-[#ff0e18] mb-2 text-center">
                      {product.title}
                    </h3>
                    <p className="text-sm sm:text-base h-auto md:h-[65px]">
                      {product.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          {/* Right Section - full width on mobile, 40% on large screens */}
          <div className="w-full lg:w-2/5 flex items-center justify-center">
            <div className="w-full bg-gray-200 rounded-lg overflow-hidden">
              <img
                src="/home_images/home-middle-image-1024x1024.jpeg"
                alt="Additional Info"
                className="w-full h-64 md:h-[400px] lg:h-[600px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
