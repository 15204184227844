import { Phone, Mail, MapPin, Printer } from 'lucide-react';

export default function CompanyDetails() {
  return (
    <div className="bg-white py-8 px-4 md:px-12">
      <h2 className="text-4xl font-bold text-[#0d2251] mb-6 text-center">Corporate Office:</h2>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {/* Phone Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <Phone size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Phone</h2>
          <p className="text-gray-700">+91-40-2784 8345, 2784 8333</p>
        </div>

        {/* Fax Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <Printer size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Fax</h2>
          <p className="text-gray-700">+91-40-2781 9933</p>
        </div>

        {/* Address Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <MapPin size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Address</h2>
          <p className="text-gray-700">G11, Minerva Complex, Sarojini Devi Road, Secunderabad-500 003, Telangana</p>
        </div>

        {/* Email Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center text-center min-h-[150px]">
          <Mail size={32} className="text-[#0d2251] mb-2" />
          <h2 className="text-lg font-semibold mb-1">Email</h2>
          <p className="text-gray-700">info@stayonpapers.com</p>
        </div>
      </div>
    </div>
  );
}
