import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <img 
      src="/home_images/home-image-1.png" 
      alt="Business Solutions" 
      className="w-full h-[600px] object-cover rounded-md" // Full width, custom height, and cover for aspect ratio
    />
  );
}
