import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function WhoWeAre() {
  return (
    <div className="flex flex-col md:flex-row w-full h-auto md:h-[500px] rounded-md overflow-hidden">
      {/* Image Section - 60% width on desktop, full width on mobile */}
      <img 
        src="/home_images/stayonpaper_heroku.jpg" 
        alt="Business Solutions" 
        className="w-full md:w-3/5 h-64 md:h-full object-cover"
      />
      
      {/* Text Section - 40% width on desktop, full width on mobile */}
      <div className="w-full md:w-2/5 bg-gray-300 p-4 md:p-8 flex flex-col justify-center space-y-4 md:space-y-6">
        {/* Heading */}
        <h1 className="text-2xl md:text-3xl font-bold text-[#ff0e18]">Who We Are</h1>
        
        {/* Subheading */}
        <h2 className="text-lg md:text-xl text-[#0d2251]">We understand the paramount importance of labels!</h2>
        
        {/* Card Section */}
        <div className="bg-white shadow-md p-4 md:p-6 rounded-md">
          <p className="text-[#0d2251] mb-4">
            STAY – ON papers is one of the pioneers in adhesive coating, setup with American Knowhow and equipment. Our coating process is based on the latest Hot Melt Adhesive Technology with 10”, 20” and New 40″ Coater to master the fast growing market needs.
          </p>
        </div>
      </div>
    </div>
  );
}
