import { ShieldCheck, Briefcase, Archive, FileText, Tag, Settings, Search, Ruler, CheckCircle, Box, Book, BarChart, GraduationCap, ShoppingBag } from 'lucide-react';

const qualityValues = [
  { icon: Briefcase, title: 'Management Representative', description: 'Oversees compliance and quality standards across organizational operations.', color: '#1e40af' }, // Navy
  { icon: BarChart, title: 'Management Review', description: 'Conducts regular reviews to align processes with quality objectives.', color: '#3b82f6' }, // Blue
  { icon: ShieldCheck, title: 'Quality System', description: 'Ensures consistent quality across all products and processes.', color: '#0ea5e9' }, // Sky Blue
  { icon: FileText, title: 'Document Control', description: 'Manages documentation with strict version control for reliability.', color: '#6366f1' }, // Indigo
  { icon: Tag, title: 'Product Identification & Traceability', description: 'Tracks each product to maintain precise traceability throughout its lifecycle.', color: '#10b981' }, // Emerald
  { icon: Settings, title: 'Process Control', description: 'Implements standards for controlled and efficient processes.', color: '#f59e0b' }, // Amber
  { icon: Search, title: 'Inspection Testing', description: 'Conducts detailed inspections to maintain product integrity.', color: '#d97706' }, // Orange
  { icon: Ruler, title: 'Inspection Measuring & Test Equipment', description: 'Ensures tools meet calibration standards for accuracy.', color: '#9333ea' }, // Purple
  { icon: CheckCircle, title: 'Inspection & Test Status', description: 'Marks each product’s inspection status for clear visibility.', color: '#14b8a6' }, // Teal
  { icon: Box, title: 'Handling, Storage, Packaging & Delivery', description: 'Optimizes each stage for safe, secure product handling and delivery.', color: '#84cc16' }, // Lime
  { icon: Book, title: 'Quality Records', description: 'Maintains records to document quality processes and standards.', color: '#dc2626' }, // Red
  { icon: BarChart, title: 'Internal Quality Audits', description: 'Conducts regular audits to verify adherence to quality procedures.', color: '#22c55e' }, // Green
  { icon: GraduationCap, title: 'Training', description: 'Provides ongoing training for team proficiency in quality standards.', color: '#6366f1' }, // Indigo
  { icon: ShoppingBag, title: 'Purchasing', description: 'Sources high-quality materials from trusted suppliers to uphold standards.', color: '#f43f5e' }, // Pink
];

export default function QualityManagement() {
  return (
    <section className="py-20 bg-[#f5f7fa]">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-[#0d2251] mb-4">Quality Management</h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {qualityValues.map((item, index) => (
            <div
              key={index}
              className="bg-white border border-gray-200 p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow flex items-start space-x-4"
            >
              <item.icon className="w-10 h-10" style={{ color: item.color }} />
              <div>
                <h3 className="text-lg font-semibold text-[#0d2251] mb-1">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
