import { Code2, Github, Linkedin, Twitter, MapPin, Phone, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-[#0d2251] text-gray-300 w-full">
      <div className="container mx-auto px-8 md:px-16 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          
          {/* Column 1: Company Logo */}
          <div>
            <Link to="/" className="flex items-center space-x-2">
              <img src="/home_images/logo-stay-on-paper.png" alt="Ajna" className="h-auto w-60" />
            </Link>
          </div>
          
          {/* Column 2: Quick Links */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Quick Links</h3>
            <ul className="space-y-3">
              <li><Link to="/" className="text-base hover:text-[#cf772f] transition-colors">Home</Link></li>
              <li><Link to="/about" className="text-base hover:text-[#cf772f] transition-colors">About Us</Link></li>
              <li><Link to="/services" className="text-base hover:text-[#cf772f] transition-colors">Services</Link></li>
              <li><Link to="/contact" className="text-base hover:text-[#cf772f] transition-colors">Contact</Link></li>
            </ul>
          </div>
          
          {/* Column 3: Products */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Our Products</h3>
            <ul className="space-y-3">
              <li className="text-base">Paper</li>
              <li className="text-base">Film</li>
              <li className="text-base">Tapes</li>
              <li className="text-base">Label Stock</li>
            </ul>
          </div>
          
          {/* Column 4: Contact Information */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Contact Us</h3>
            <div className="flex items-start space-x-2">
              <MapPin className="h-16 w-16 text-gray-400" />
              <p className="text-base">G11, Minerva Complex, Sarojini Devi Road, Secunderabad-500 003, Telangana</p>
            </div>
            <div className="flex items-center space-x-2 mt-3">
              <Phone className="h-6 w-6 text-gray-400" />
              <p className="text-base">+91-40-2784 8345, 2784 8333</p>
            </div>
            <div className="flex items-center space-x-2 mt-3">
              <Mail className="h-6 w-6 text-gray-400" />
              <p className="text-base">
                <a href="mailto:info@stayonpapers.com" className="hover:text-[#cf772f] transition-colors">info@stayonpapers.com</a>
              </p>
            </div>
          </div>
        </div>
        
        {/* Footer Bottom */}
        <div className="border-t border-gray-800 mt-8 pt-8 flex justify-between items-center">
          <p className='text-left'>© {currentYear} Copyright @ 2024 STAY-ON PAPERS. All Rights Reserved.</p>
          <a href="https://ajna.cloud/" className='text-right'>Designed by Ajna.cloud</a>
        </div>

      </div>
    </footer>
  );
}
