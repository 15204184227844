import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const services = [
    {
        image: '/home_images/oil-industry-home-1.jpg', // Update with the correct image path
        title: 'OIL & INDUSTRIAL CHEMICAL',
        description:
          'At Stay On Papers, we empathize with client requirements and recommend the optimal labeling solutions.',
      },
      {
        image: '/home_images/home-bottom-image-2.png', // Update with the correct image path
        title: 'DIGITAL PRINTING',
        description:
          'We provide groundbreaking label stock solutions that surpass traditional printing technologies, offering exceptional quality.',
      },
      {
        image: '/home_images/industry-icon-food-home-3png.png', // Update with the correct image path
        title: 'FOOD & BEVERAGE',
        description:
          'Label stock application in the Food and Beverages industry is exceptionally varied and serves a crucial role in product presentation.',
      },
      {
        image: '/home_images/home-bottom-4.png', // Update with the correct image path
        title: 'WINE & SPIRITS',
        description:
          'At Stay On Papers, we specialize in enhancing the visual appeal of wine and spirit bottles, making them truly distinct and captivating.',
      },
      {
        image: '/home_images/industry-icon-home-bottom-5.png', // Update with the correct image path
        title: 'HOME & PERSONAL CARE',
        description:
          'We provide an extensive selection of paper and film label materials for a wide array of home and personal applications, catering to diverse needs.',
      },
      {
        image: '/home_images/home-bottom-6.png', // Update with the correct image path
        title: 'PHARMACEUTICAL & HEALTHCARE',
        description:
          'Our unparalleled industry expertise and over a decade of dedicated research and development empower us to offer top-notch labeling solutions.',
      },
];

export default function IndustriesServed() {
  return (
    <section className="bg-gray-50">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-[#0d2251] mb-6">Industries</h2>
          <p className="text-lg text-[#0d2251] max-w-2xl mx-auto">
            Our label stock and release liner solutions are utilized by a broad range of industries.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-2"
            >
              <img
                src={service.image}
                alt={service.title}
                className="h-40 w-full object-cover rounded-lg mb-6"
              />
              <div className="bg-[#f0f8ff] p-5 rounded-lg shadow-inner">
                <h3 className="text-2xl font-semibold text-[#0d2251] mb-3">
                  {service.title}
                </h3>
                <p className="text-md text-[#0d2251] leading-relaxed mb-5">
                  {service.description}
                </p>
                <Link
                  to={`/${service.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className="inline-flex items-center text-blue-600 font-semibold hover:underline"
                >
                  Learn more <ArrowRight className="ml-2" size={18} />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
