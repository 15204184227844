import HeroQualtiy from "../components/quality_culture/Hero.js";
import Qualities from "../components/quality_culture/Qualities.js";

export default function QualityCulture() {
  return (
    <div>
      <HeroQualtiy />
      <Qualities />
    </div>
  );
}