import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const products = [
  {
    image: '/home_images/home-image-1.png', // Add the correct image path
    title: 'Paper Label Stock'
  },
  {
    image: '/home_images/home-image-2.png', // Add the correct image path
    title: 'Filmic Label Stock'
  },
  {
    image: '/home_images/home-image-3.png', // Add the correct image path
    title: 'Speciality Tape'
  },
  {
    image: '/home_images/home-image-4.png', // Add the correct image path
    title: 'Speciality Label Stock'
  },
  {
    image: '/home_images/home-image-5.png', // Add the correct image path
    title: 'Release Liners'
  },
  {
    image: '/home_images/home-image-6.png', // Add the correct image path
    title: 'Chromo Art Label Stock'
  },
];

export default function Products() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-[#0d2251] mb-4">Products</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <img
                src={product.image}
                alt={product.title}
                className="h-90 w-full object-contain rounded-md mb-4" // Updated to "object-contain"
              />
              <div className="bg-[#e0eaf4] p-4 rounded-md mb-4">
                <h3 className="text-xl font-semibold text-[#ff0e18] mb-2 text-center">
                  {product.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
